/* You can add global styles to this file, and also import other style files */
@import "../src/app/helper/theme.css";
@font-face {
  font-family: Poppins;
  src: url("https://fonts.googleapis.com/css2?family=Mulish:wght@300&family=Poppins:wght@300&display=swap");
  /* font-weight: bold; */
}
body {
  /* font-family: "Nunito Sans", sans-serif; */
  background: white;
  /* "Roboto", sans-serif */
}
.containerWhite {
  background: #ffffff;
  border-radius: 12px;
  margin-top: 1%;
  width: 97%;
  padding: 9px 15px 15px 15px !important;
}

p {
  font-size: 15px;
  /* font-family: "Nunito Sans", sans-serif; */
  color: #3d3d3d;
  font-weight: 400;
  line-height: 25px;
  margin: 0 0 15px 0;
}
h6 {
  margin: 0;
  color: #111111;
  font-weight: 400;
  /* font-family: "Nunito Sans", sans-serif; */
}

.float-buttom-RB {
  border-radius: 30px;
  border: none;
  width: 60px;
  height: 60px;
}

hr.highlighter {
  width: 80px;
  border-color: #0071e3;
  border-width: 2px;
}

.titleMoradito {
  color: #041e42;
  font-size: 20px;
  font-weight: bold;
}

.secondary-color {
  color: #0f387a;
}

.bo-save-btn {
  font-weight: 500;
  background-color: #4752a3!important;
}

/* Navy: 0B2E59 y 006759
Oranges: EE3901 y FF6C00 */